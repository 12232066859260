/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.header-action-2 .header-action-icon-2 > a {
  color: #000 !important;
}

.flex-container {
  display: flex;
  padding-top:15px;
}
.flex-item {
  flex-basis: 50px;
  width: 50px;
  /* margin: 30px; */
  text-align: center;
  letter-spacing: 1px;
}

.navbar > div.container-fluid {
  justify-content:flex-start;
}

.navbar-nav a {
  padding: 0 15px 0 15px;
  color: #253d4e;
  line-height:40px;
}

.nav-link > a {
  color: #253d4e;
}
