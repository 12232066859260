@media only screen and (max-width:768px){
	.totall-product h2 {
		font-size: 28px;
	}
	.loop-grid.loop-list article {
		.post-thumb {
			min-height: 220px;
			min-width: 300px;
		}
		h3.post-title {
			font-size: 22px;
			margin-bottom: 30px !important;
		}
		.post-exerpt {
			display: none;
		}
		.entry-content-2.pl-50 {
			padding-left: 30px !important;
		}
	}
	.loop-big {
		h2.post-title {
			font-size: 32px;
		}
	}
	.header-style-1 .header-bottom-bg-color {
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
	}
	.header-action-2 .header-action-icon-2:last-child {
		padding: 0;
	}
	.logo {
		&.logo-width-1 {
			margin-right: 0;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			a {
				img {
					width: 150px;
					min-width: 150px;
				}
			}
		}
	}
	.header-bottom  {
		padding: 20px 0;
	}
	.header-action {
		.header-action-icon {
			margin-right: 15px;
			&.header-action-mrg-none {
				margin-right: 15px;
			}
			&.header-action-mrg-none2 {
				margin-right: 15px;
			}
			& > a {
				font-size: 20px;
			}
		}
	}
	.header-action-2 {
		.header-action-icon-2 {
			padding: 0 6px;
			&:last-child {
				padding: 0 0 0 6px;
			}
			& > a {
				color: #fff;
				fill: #fff;
				font-size: 22px;
				margin-right: 10px;
			}
		}
	}

	.cart-dropdown-wrap {
		width: 290px;
		right: -39px;

		ul {
			li {
				.shopping-cart-img {
					-webkit-box-flex: 0;
					-webkit-flex: 0 0 60px;
					-ms-flex: 0 0 60px;
					flex: 0 0 60px;
					margin-right: 10px;
				}
			}
		}
		.shopping-cart-footer {
			.shopping-cart-button {
				a {
					padding: 12px 22px 13px;
				}
			}
		}
	}
	.categories-dropdown-wrap {
		ul {
			li {
				a {
					padding: 5px 12px;
				}
			}
		}
	}
	.search-popup-wrap {
		.search-popup-content {
			form {
				input {
					width: 270px;
					font-size: 25px;
					padding: 21px 20px 12px 0;
				}
			}
		}
	}
	.mobile-header-wrapper-style {
		width: 380px;
		.mobile-header-wrapper-inner {
			.mobile-header-top {
				padding: 15px 30px 13px 30px;
				border-bottom: 1px solid $border-color;
				.mobile-header-logo a img {
					width: 140px;
				}
			}
			.mobile-header-content-area {
				padding: 30px;
			}
		}
	}
	.header-height-1 {
		min-height: 65px;
	}
	.header-height-2 {
		min-height: 65px;
	}
	.home-slider.pt-50 {padding-top: 0 !important;}
	.hero-slider-content-2 {
		h1 {
			font-size: 32px;
			line-height: 1.2;
		}
		h2 {
			font-size: 30px;
			line-height: 1.2;
			margin-bottom: 10px;
		}
		h4 {
			font-size: 16px;
			margin: 0 0 10px;
		}
		.btn-default {
			color: #fff;
			background-color: $color-brand;
			border-radius: 50px;
			padding: 10px 22px;
			font-size: 14px;
		}
		p {
			font-size: 14px;
			line-height: 1.3;
		}
	}
	.banner-big {
		h4 {font-size: 12px;}
		h2 {font-size: 16px;}
		.btn {
			background-color: $color-brand;
			color: #fff;
			border-radius: 3px;
			font-size: 13px;
			padding: 10px 22px;
			border: 0;
		}
	}
	.home-slider {
		.slider-arrow {
			display: none;
		}
		.hero-slider-1 {
			height: 350px;
			&.style-2 {
				height: 400px;
				.hero-slider-content-2 {
					padding-left: 0;
					padding-top: 100px;
				}
				.single-slider-img {
					img {
						max-width: 400px;
					}
				}
				.slider-1-height-2 {
					height: 400px;
					position: relative;
				}
			}
		}
	}
	.banner-img {
		float: left;
		&.f-none {
			float: none;
		}
	}
	.hero-slider-1.style-3 .slider-1-height-3 {
		height: 390px;
	}
	.hero-slider-1.style-3 .hero-slider-content-2 {
		padding-left: 20px;
	}
	.home-slide-cover .hero-slider-1.style-4 {
		.hero-slider-content-2 {
			padding-left: 0;
			h1 {font-size: 30px;}
			h2 {font-size: 14px;}
			h4 {font-size: 12px;}
		}
	}
	.header-style-3 {
		.header-bottom-bg-color {
			border-bottom: 2px solid $color-brand;
		}
		.main-nav {
			border: none !important
		}
		.sticky-bar {
			&.stick.sticky-blue-bg {
				background-color:  $color-brand;
			}
		}
	}
	.header-style-4 {
		.main-nav {
			border: none !important
		}
		.sticky-bar {
			&.stick.sticky-blue-bg {
				background-color:  $color-brand;
			}
		}
	}
	.font-xxl {
		font-size: 38px;
	}
	.single-page.pl-30, .single-page.pr-30 {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
	.download-app {margin-bottom: 0 !important;}
	.footer-mid {
		.logo {
			img {
				max-width: 150px;
			}
		}
		.widget-install-app, .widget-about {
			min-width: 205px;
		}
		.widget-about strong{display: none;}
	}
	.product-list {
		.product-cart-wrap {
			align-items: start;
			.product-img-action-wrap {
				max-width: 45%;
			}
			h2 {
				font-size: 24px;
			}
			p.mt-15 {
				display: none;
			}
			.product-rate-cover {
				margin-bottom: 20px;
			}
		}

	}
	.detail-info {
		padding: 0 !important;
		h2 {
			font-size: 30px;
		}
		.detail-extralink .detail-qty {
			padding: 11px 20px 11px 10px;
			max-width: 60px;
		}
		.product-extra-link2 .button.button-add-to-cart {
			padding: 0px 15px;
			i {
				display: none;
			}
		}
	}
	.shopping-summery {
		table tbody tr img {
			max-width: 80px;
			margin-right: 15px;
		}
		.form-check-label {
			display: none;
		}
		h6 {
			font-size: 14px;
		}
		td.pl-30 {
			padding-left: 0 !important;
		}
		button.btn {
			width: 120px;
			margin-left: 15px;
		}
	}
	.product-cart-wrap .product-img-action-wrap .product-action-1 {
		min-width: 111px;
	}
	.custom-modal .modal-dialog {
		max-width: 720px !important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
	.mobile-promotion {
		display: block;
		padding: 7px 0;
		text-align: center;
		background: $color-brand;
		color: #fff;
	}
}
/*Mobile landscape*/
@media only screen and (min-width:576px) and (max-width:767px){
	.header-action {
		.header-action-icon {
			margin-right: 20px;
			&.header-action-mrg-none {
				margin-right: 20px;
			}
			&.header-action-mrg-none2 {
				margin-right: 20px;
			}
			& > a {
				font-size: 22px;
			}
		}
	}
	.header-action-2 {
		.header-action-icon-2 {
			padding: 0 10px;
			&:last-child {
				padding: 0 0 0 10px;
			}
			& > a {
				font-size: 22px;
			}
		}
	}
	.cart-dropdown-wrap {
		width: 310px;
	}
	.search-popup-wrap {
		.search-popup-content {
			form {
				input {
					width: 480px;
					font-size: 25px;
					padding: 21px 20px 12px 0;
				}
			}
		}
	}
	.comment-form .name{padding-right: 0px;
		margin-bottom: 1rem;
	}
	.header-style-5 .search-style-2 {display: none;}

}
/*Tablet*/
@media only screen and (min-width:768px) and (max-width:991px){
	.header-action {
		.header-action-icon {
			margin-right: 33px;
			&.header-action-mrg-none {
				margin-right: 33px;
			}
			&.header-action-mrg-none2 {
				margin-right: 33px;
			}
		}
	}
	.header-action-2 {
		.header-action-icon-2 {
			& > a {
				color: #fff;
				fill: #fff;
			}
		}
	}
	.search-popup-wrap {
		.search-popup-content {
			form {
				input {
					width: 650px;
					font-size: 35px;
				}
			}
		}
	}
	.header-height-1 {
		min-height: 50px;
	}
	.header-height-2 {
		min-height: 50px;
		border-bottom: 2px solid $color-brand;
	}
	.header-height-3 {
		min-height: 50px;
	}
	.header-height-4 {
		min-height: 50px;
	}
	.header-style-5 {
		.header-bottom {
			padding: 5px 0;
			border-bottom: 1px solid #f7f8f9;
		}
	}
	.comment-form .email{padding-left: 0px;}
	.loop-grid.pr-30 {
		padding-right: 0 !important;
	}
}
/*Desktop*/
@media only screen and (min-width:992px) and (max-width:1199px){
	.totall-product h2 {
		font-size: 28px;
	}
	.loop-grid.loop-list article {
		.post-thumb {
			min-height: 220px;
			min-width: 300px;
		}
		h3.post-title {
			font-size: 22px;
			margin-bottom: 30px !important;
		}
		.post-exerpt {
			display: none;
		}
		.entry-content-2.pl-50 {
			padding-left: 30px !important;
		}
	}
	.logo {
		&.logo-hm3 {
			a {
				img {
					width: 120px;
				}
			}
		}
	}
	.header-style-5 {
		.search-style-2 {
			display: none;
		}
	}
	.main-menu {
		&.main-menu-mrg-1 {
			margin: 0 0 0 15px;
		}
		& > nav {
			& > ul {
				& > li {
					ul {
						&.sub-menu {
							li {
								ul {
									&.level-menu {
										&.level-menu-modify {
											width: 165px;
										}
									}
								}
							}
						}
						&.mega-menu {
							li {
								.menu-banner-wrap {
									.menu-banner-content {
										left: 20px;
										top: 22px;
										h3 {
											font-size: 20px;
											line-height: 1.3;
											margin: 5px 0 7px;
										}
										.menu-banner-price {
											margin: 0 0 10px;
										}
										.menu-banner-btn {
											a {
												padding: 10px 16px 13px;
											}
										}
									}
									.menu-banner-discount {
										width: 70px;
										height: 70px;
										line-height: 70px;
									}
								}
							}
						}
					}
				}
			}
		}
		&.main-menu-padding-1 {
			& > nav {
				& > ul {
					& > li {
						padding: 0 8px;
					}
				}
			}
			&.hm3-menu-padding {
				& > nav {
					& > ul {
						& > li {
							padding: 0 6px;
						}
					}
				}
			}
		}
	}
	.search-style-1 {
		margin-right: 15px;
		form {
			width: 170px;
		}
	}
	.header-action {
		&.header-action-hm3 {
			.header-action-icon {
				margin-right: 15px;
				&.header-action-mrg-none2 {
					margin-right: 0;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	.search-style-2 {
		form {
			input {
				width: 340px;
			}
		}
	}
	.hotline {
		p {
			font-size: 15px;
		}
	}
	.search-popup-wrap {
		.search-popup-content {
			form {
				input {
					width: 820px;
				}
			}
		}
	}
	.header-height-1 {
		min-height: 157px;
	}

	.header-height-3 {
		min-height: 133px;
	}
	.header-height-4 {
		min-height: 144px;
	}
	.hero-slider-1 {
		height: 350px;
		.single-slider-img-1 {
			height: 350px;
		}
	}

}
@media (min-width: 992px) {
	.col-md-1-5 { width: 20%; }
	.col-md-2-5 { width: 40%; }
	.col-md-3-5 { width: 60%; }
	.col-md-4-5 { width: 80%; }
	.col-md-5-5 { width: 100%; }
  }
/*Wide screen*/
@media only screen and (min-width:1600px) {
	.header-action-right {
		.search-location {
			display: block;
		}
	}

}
@media only screen and (min-width:1200px) {
	.container {
		max-width: 1610px;
	}
	.col-lg-1-5 { width: 20%; }
	.col-lg-2-5 { width: 40%; }
	.col-lg-3-5 { width: 60%; }
	.col-lg-4-5 { width: 80%; }
	.col-lg-5-5 { width: 100%; }
	.header-action-right {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}
}
@media only screen and (min-width:1200px) and (max-width:1365px){
	.main-menu.main-menu-padding-1 > nav > ul > li{padding:0 14px;}
}

@media only screen and (max-width:1400px) {
	.product-cart-wrap.style-2 .product-content-wrap {
		max-width: 94%;
	}
	.hero-slider-1 .single-hero-slider  {height: 467px;}
	.display-2 {font-size: 64px;}
	.banner-img.style-2 {height: 483px;}
	.slider-nav-thumbnails {
		button.slick-arrow {
			&.slick-prev {
				left: 10px;
			}
			&.slick-next {
				right: 10px;
			}
		}
	}
	.zoomContainer {
		display: none;
	}
	.detail-info h2 {
		font-size: 30px;
	}
	.tab-style3 {
		.nav-item {margin-bottom: 15px;}
	}
}

@media only screen and (max-width:1024px) {
	.header-style-1 .main-categori-wrap {
		margin-right: 20px;
	}
	.product-cart-wrap .product-action-1 a.action-btn {
		width: 34px;
		height: 34px;
		line-height: 40px;
		font-size: 13px;
	}
	.banner-big {
		h1 {
			font-size: 22px;
		}
		.btn {
			border-radius: 3px;
			font-size: 12px;
			padding: 6px 12px;
			border: 0;
		}
	}
	.banner-img .banner-text h4 {
		margin-top: 0px !important;
	}
	.header-wrap .header-right {
		overflow: hidden;
	}
	.footer-link-cover {
		display: block;
		.footer-link-widget {
			margin-right: 30px;
			float: left;
			margin-bottom: 30px;
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
	.et,.hotline, li.hot-deals, .header-action-2 .header-action-icon-2 span.lable  {display: none !important;}
	.main-menu.main-menu-padding-1 > nav > ul > li {
		padding: 0 10px;
	}
	.hero-slider-1 .single-hero-slider  {
		height: 350px;
		.display-2 {
			font-size: 46px;
			margin-bottom: 25px !important;
		}
		.slider-content p {
			font-size: 22px;
			margin-bottom: 40px !important;
		}

	}
	.banner-img .banner-text h4 {
		min-height: 50px;
		font-size: 18px;
	}
	.header-style-1 .search-style-2 form {max-width: 450px;}
	.header-action-2 .header-action-icon-2:last-child {
		padding: 0 0 0 8px;
	}

	.nav-tabs.links .nav-link {
		padding: 0 7px;
		font-size: 14px;
	}
	.deals-countdown .countdown-section {
		padding: 20px 2px 30px 2px;
		margin-left: 2px;
        margin-right: 2px;
	}
	.section-title.style-1 {
		padding-bottom: 15px;
		font-size: 20px;
	}
	.product-list-small {
		h6 {
			font-size: 14px;
		}
		.product-rate-cover {display: none;}
	}
	.section-title.style-2 {
		display: block;
		h3 {
			margin-bottom: 25px;
			font-size: 28px;
		}
	}
	.modal-open .modal {
		padding-right: 0 !important;
	}
	.vendor-wrap.style-2 {
		display: block;
	}
	.mt-md-30  {margin-top: 30px !important;}
	.banner-img.style-4 .banner-text h4 {font-size: 20px;}
	.header-style-1.header-style-5 .header-bottom-bg-color {
		background-color: #fff !important;
	}
}
/*small phone*/
@media only screen and (max-width:480px){
	.archive-header {
		padding: 30px;
	}
	.mobile-promotion {
		display: block;
		padding: 7px 0;
		text-align: center;
		background: #3BB77E;
		color: #fff;
	}
	.loop-big {
		h2.post-title {
			font-size: 22px;
		}
	}
	.entry-meta.meta-1 {
		justify-content: start;
	}
	.single-header-meta .single-share {
		display: none;
	}
	.single-content blockquote {
		padding: 20px 30px;
		border-radius: 15px;
		margin: 20px auto;
		font-size: 16px;
		max-width: 100%;
		p {
			font-size: 16px;
			line-height: 22px;
		}
	}
	.hero-slider-1 {
		.single-hero-slider {
			.display-2 {
				font-size: 32px;
			}
			.slider-content {
				p {
					font-size: 16px;
					margin-bottom: 40px !important;
				}
				form {
					max-width: 310px;
					button.submit, button[type="submit"] {
						padding: 12px 20px;
					}
				}
			}
		}

	}
	.security-code {
		padding: 0 20px;
	}
	.post-list .post-thumb {
		max-width: unset;
		margin-right: 0 !important;
	}
	.entry-meta.meta-2 {
		a.btn {
			display: inline-block;
			align-items: center;
			align-self: center;
		}

		.font-xs {
			max-width: 150px;
			line-height: 1.3;
		}
	}
	.banner-bg {h2 {font-size: 20px;}}
	.single-header .single-header-meta, .entry-bottom {display: block;}
	.carausel-6-columns, .carausel-4-columns {max-width: 375px;overflow: hidden;}

	.hero-slider-1 {
		height: unset;
	}
	.hero-slider-content-2 {
		text-align: center;
		padding-top: 20px;
		p {
			width: 100%;
		}
	}
	.header-height-2 {
		min-height: 40px;
	}
	.banner-left-icon,.banner-img {
		margin-bottom: 15px;
	}
	.header-action-2 .header-action-icon-2:last-child {
		padding: 0;
	}
	.popular-categories {
		.slider-btn.slider-prev {
			right: 50px !important;
			left: unset !important;
		}
	}
	.mb-sm-0 {margin-bottom: 0 !important;}
	.mb-sm-4 {margin-bottom: 1rem;}
	.mb-sm-5 {margin-bottom: 2rem;}
	.heading-tab {display: block !important;
		h3.section-title {margin-bottom: 15px !important;}
	}
	.nav.right {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
		justify-content: start;
	}
	.hero-slider-1.style-2 .single-slider-img {
		display: none;
	}
	ul.footer-list {
		margin-bottom: 30px;
	}
	.section-padding-60 {
		padding: 30px 0;
	}
	.pt-60, .pt-40 {
		padding-top: 30px !important;
	}
	.pb-60 {
		padding-bottom: 30px !important;
	}
	.mb-60 {
		margin-bottom: 30px !important;
	}

	.hero-slider-1.style-3 .slider-1-height-3 {
		height: 220px;
	}
	.hero-slider-1.style-3 .hero-slider-content-2 {
		position: relative;
		text-align: left;
		padding-left: 15px;
		padding-top: 0;
		h1 {
			font-size: 18px;
		}
		h2 {
			font-size: 16px;
		}
		h4 {
			font-size: 14px;
		}
		p {
			font-size: 11px;
		}
		a.btn {
			display: none;
		}
	}
	.header-style-5 {
		.search-style-2 {
			display: none;
		}
		.header-action-2 .header-action-icon-2 > a {
			font-size: 18px;
		}
		.sticky-bar.stick.sticky-white-bg {
			background-color: #fff;
			padding: 15px 0;
		}
	}
	.font-xxl {
		font-size: 28px;
	}
	.w-50 {
		width: 100% !important;
	}

	.product-list {
		.product-cart-wrap {
			display: block;
			.product-img-action-wrap {
				max-width: 100%;
			}
		}
	}
	.shop-product-fillter {
		display: block;
		.totall-product {
			margin-bottom: 15px;
		}
	}
	.table {
		td {
			display: block;
			width: 100%;
			text-align: center;
			&::before {
				content: attr(data-title) " ";
				float: left;
				text-transform: capitalize;
				margin-right: 15px;
				font-weight: bold;
			}
		}
		thead {
			display: none;
		}
	}
	.loop-grid.pr-30 {
		padding-right: 0 !important;
	}
	.loop-grid.loop-list article {
		display: block;
	}
	.d-sm-none {display: none;}
	.banner-features {margin-bottom: 15px;}
	.product-cart-wrap:not(:last-child), .mb-xs-30 {margin-bottom: 30px !important;}
	.slick-track .product-cart-wrap {margin-bottom: 0 !important;}

	.first-post {
		.meta-1 {
			.font-sm {
				display: none;
			}
		}
		.btn.btn-sm {
			display: none;
		}
	}
	.loop-grid .entry-content {
		padding: 20px 20px 0 20px;
	}
	.img-hover-slide {
		min-height: 232px;
	}
	.comments-area .thumb {
		min-width: 100px;
	}
	.hero-slider-1 .single-slider-img-1 {
		height: 300px;
	}
	.featured {
		.col-lg-2 {width: 50%;}
	}
	.nav-tabs .nav-link {font-size: 13px;padding: 10px 12px;}
	.deal {background-position: left bottom;}

	.deals-countdown .countdown-section {
		padding: 20px 2px 30px 2px;
		margin-left: 2px;
        margin-right: 2px;
	}
	.banner-bg {
		padding: 30px;
	}
	.product-list-small {
		figure {margin-bottom: 20px !important;}
		.title-small {
			font-size: 16px;
			font-weight: 600;
		}
	}
	.newsletter {
		.des {display: none;}
		form {margin: 15px 0;}
	}
	footer {
		.col-lg-2.col-md-3 {
			width: 50%;
		}
		.download-app a img {width: 150px;}
	}
	.home-slider .hero-slider-1.style-2 .hero-slider-content-2 {
		padding-left: 0;
		padding-top: 50px;
		h3 {
			line-height: 1.4;
		}
	}
	.hero-slider-1.style-3.dot-style-1.dot-style-1-position-1 ul {
		bottom: 0;
	}
	.single-content .banner-text, .banner-img.banner-big .banner-text{display: none;}
	.comments-area {
		padding: 25px 0;
		margin-top: 0;
	}
	.entry-bottom {margin-bottom: 0 !important;}
	section.pt-150.pb-150 {padding: 50px 0 !important;}
	.product-detail {
		.single-share {margin-bottom: 20px;}
		.tab-style3 .nav-tabs li.nav-item a {
			padding: 0.5rem;
			text-transform: none;
		}
	}
	.related-products .product-cart-wrap {margin-bottom: 30px;}
	.mb-sm-15 {margin-bottom: 15px;}
	.section-title {
		display: block;
		margin-bottom: 15px;
		.title {
			display: block;

		}
		h3 {
			margin-bottom: 20px;
			font-size: 28px;
		}
		.show-all {
			display: none;
		}
	}
	.nav-tabs.links .nav-link {
		margin-bottom: 10px;
	}
	.slider-arrow.slider-arrow-2.flex-right {
		display: none;
	}
	.product-grid-4 .product-cart-wrap {margin-bottom: 30px;}
	.product-list-small {
		figure.col-md-4 {
			max-width: 30%;
			float: left;
			margin: 0 !important;
		}
		.col-md-8 {
			float: left;
			max-width: 70%;
		}
	}
	.newsletter .newsletter-inner {
		padding: 20px;
		h2 {
			font-size: 22px;
		}
		.newsletter-content p {
			font-size: 14px;
			margin-bottom: 25px !important;
		}
		button.submit, button[type="submit"] {
			padding: 12px 20px;
		}

	}
	.footer-link-widget:not(:last-child) {
		margin-right: 0;
	}
	.widget-about {
		margin-bottom: 30px;
	}
	footer p.font-md {font-size: 13px;}
	.hero-slider-1 .single-hero-slider.rectangle .slider-content {
		width: 100%;
	}
	.product-info {
		border: 0;
		padding: 0;
		.tab-style3 .nav-tabs li.nav-item a {
			padding: 11px 12px !important;
			font-size: 13px;
		}
	}
	.shopping-summery {
		table tbody tr img {
			max-width: 180px;
			margin-right: 0;
		}
	}
	.toggle_info, .apply-coupon {
		.font-lg, input {
			font-size: 14px !important;
		}
	}
	.cart-totals.ml-30 {
		margin-left: 0 !important;
		text-align: center;
	}
	.order_table table .w-160 {
		margin: 0 auto;
	}
	.modal-open .modal {
		padding-right: 0 !important;
	}
	.archive-header-3 {
		padding: 30px;
		.archive-header-3-inner {
			display: block;
		}
	}
}

/*phone landscape*/
@media only screen and (min-width: 480px) and (max-width: 667px) {

	.header-height-2 {
		min-height: 40px;
	}
	.col-lg-4 {
		.banner-img {
			margin-bottom: 30px;
		}
	}
	.banner-features {margin-bottom: 30px;}

	.modal-open .modal {
		padding-right: 0 !important;
	}

}

/*small phone*/
@media only screen and (max-width:375px){
	.entry-meta .hit-count, .entry-meta.meta-2 .font-xs {display: none;}
	.deal {
		padding: 30px;
	}
	.custom-modal .modal-dialog .modal-content {
		padding: 0;
	}
	.deal .product-title {
		max-width: unset;
		font-size: 25px;
	}
	.modal-open .modal {
		padding-right: 0 !important;
	}
	.cart-action {
        flex-direction: column;
		.btn {
			width: 100%;
			margin-bottom: 15px;
		}
    }
}
@media only screen and (min-width: 375px) and (max-width: 667px) {
	.deal .product-title {
		max-width: 100%;
		font-size: 35px;
	}

}